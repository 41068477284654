<script setup>
import { Head, Link, useForm } from "@inertiajs/vue3";
import { computed, getCurrentInstance, ref } from "vue";
import AuthLayout from "@/Layouts/AuthLayout.vue";
import AuthenticationCardLogo from "@/Components/AuthenticationCardLogo.vue";

defineProps({
    canResetPassword: Boolean,
    status: String,
});

const form = useForm({
    email: "",
    password: "",
    remember: false,
});

const submit = () => {
    form.transform((data) => ({
        ...data,
        remember: form.remember ? "on" : "",
    })).post(route("login"), {
        onFinish: () => form.reset("password"),
    });
};

const vue = getCurrentInstance();
const settings = vue.appContext.config.globalProperties.$settings;

const loginImages = ["/img/login-images/login1.jpg", "/img/login-images/login2.jpg", "/img/login-images/login3.jpg", "/img/login-images/login4.jpg"];

const randomImage = () => {

    const img = loginImages[Math.floor(Math.random()*loginImages.length)];
    return img;

}
</script>

<script>
export default {
    data: () => ({}),
};
</script>

<template>
    <AuthLayout title="Login">
        <div class="md:tw-h-24 tw-h-12" />
        <v-card
            class="mx-auto d-flex flex-column justify-center align-center rounded-xl lg:tw-max-w-5xl md:tw-max-w-3xl md:tw-mb-10 tw-mb-5"
            max-width="95%">
            <v-container fluid>
                <v-form @submit.prevent="submit" :disabled="form.processing">
                    <v-row dense>
                        <v-col md="5" cols="0" class="d-md-flex d-none">
                            <v-img :src="randomImage()"
                                gradient="to bottom, rgba(20,20,20,0.1), rgba(0,0,0,.5)" height="100%" cover
                                class="rounded-xl align-end">
                                <v-card-title class="text-white" v-text="Test"></v-card-title>
                            </v-img>
                        </v-col>

                        <v-col md="7" cols="12">
                            <v-card variant="" class="md:tw-p-10 tw-p-3">
                                <v-sheet class="mx-auto d-flex flex-column justify-center align-center">
                                    <AuthenticationCardLogo class="tw-mt-1" />
                                </v-sheet>
                                <v-card-title class="mx-auto d-flex flex-column justify-center align-center">
                                    <p>Login to <i>FIRST®</i> LEGO® League</p>
                                </v-card-title>
                                <v-card-subtitle class="mx-auto d-flex flex-column justify-center align-center">
                                    {{ settings.general("finalName", "Final Name") }}
                                </v-card-subtitle>
                                <div style="height: 50px"></div>
                                <v-card-text>
                                    <v-alert v-for="item in form.errors" icon="$error" :text="item" :key="item"
                                        border="bottom" border-color="error" elevation="2" density="compact" class="mb-4">
                                    </v-alert>
                                    <v-text-field v-model="form.email" :label="$vuetify.locale.t('auth.email')"
                                        color="primary" autofocus autocomplete="username"></v-text-field>
                                    <v-text-field v-model="form.password" :label="$vuetify.locale.t('auth.password')"
                                        type="password" color="primary" autocomplete="current-password"></v-text-field>
                                    <v-checkbox v-model="form.remember" :label="$vuetify.locale.t('auth.rememberMe')" color="primary"></v-checkbox>
                                    <Link v-if="canResetPassword" :href="route('password.request')"
                                        class="tw-underline tw-text-sm tw-text-gray-600 hover:tw-text-gray-900 tw-rounded-md focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500">
                                    {{ $vuetify.locale.t("auth.forgotPassword") }}
                                    </Link>
                                </v-card-text>
                                <v-card-actions>
                                    <a href="/login/sso/authentik">
                                        <v-btn type="button" class="mt-2 d-flex ml-auto"
                                            >Login with SSO</v-btn>
                                    </a>

                                    <v-btn type="submit" class="mt-2 d-flex ml-auto" :loading="form.processing"
                                        color="primary">{{ $vuetify.locale.t("auth.login") }}</v-btn>


                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
        </v-card>
    </AuthLayout>
</template>
